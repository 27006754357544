import Vue from 'vue'

// axios
import axios from 'axios'
import vm from "@/main";

const axiosIns = axios.create({
    baseURL : 'https://fiberz.io/',
    withCredentials:false,
    //headers: {'Authorization': localStorage.getItem("token")}
  // You can add your headers here
  // ================================
  // baseURL: 'https://some-domain.com/api/',
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
})

axiosIns.interceptors.request.use(function (config) {

    console.log("request")
    //config.headers.Authorization = vm.$store.state.access_token;
    config.headers.Authorization = localStorage.getItem("token");
    // Do something before request is sent
    return config
}, function (error) {
    // Do something with request error
    return Promise.reject(error)
});

axiosIns.interceptors.response.use(function (response) {
    //2xx
    return response;
}, async function (error) {

    console.log("response")
    console.log(error);
    if (error.response.status === 401 && error.response.config.url !== "/auth/login") {
        await vm.$router.push("/auth/login");
        return false;
    }

    return Promise.reject(error);
});

Vue.prototype.$http = axiosIns

export default axiosIns
